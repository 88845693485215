var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('iq-card',{staticClass:"iq-card-block iq-card-stretch iq-card-height",attrs:{"bodyClass":"relative-background"},scopedSlots:_vm._u([{key:"headerTitle",fn:function(){return [_c('h4',{staticClass:"card-title"},[_vm._v("Daftar Data Surat Kuasa")])]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"align-items-center"},[_c('b-row',[_c('b-col',[_c('b-form-input',{staticClass:"w-50",attrs:{"id":"filter-input","type":"search","placeholder":"Cari Data"},model:{value:(_vm.filterSk),callback:function ($$v) {_vm.filterSk=$$v},expression:"filterSk"}})],1),_c('b-col',[_c('div',{staticClass:"d-inline-block float-right"},[_c('router-link',{staticClass:"btn btn-primary dark-btn-primary",attrs:{"to":{ name: 'kerjasama.sk.tambah' },"role":"button"}},[_vm._v("Tambah Data"),_c('i',{staticClass:"ri-add-line ml-2"})])],1)])],1),_c('b-table',{staticClass:"mt-2",attrs:{"responsive":"","bordered":"","fields":_vm.thead,"items":_vm.listData,"busy":_vm.isLoadingSk,"filter":_vm.filterSk,"per-page":_vm.perPageSk,"current-page":_vm.currentPageSk,"show-empty":""},on:{"filtered":_vm.onFilteredSk},scopedSlots:_vm._u([{key:"cell(nomorSuratKuasa)",fn:function(listData){return [_c('div',[_vm._v(" "+_vm._s(listData.item.nomorSuratKuasa ? listData.item.nomorSuratKuasa : '-')+" ")])]}},{key:"cell(aksi)",fn:function(listData){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('router-link',{staticClass:"btn iq-bg-success rounded-sm mr-2 mb-1",attrs:{"size":"sm","role":"button","to":{
                      name: 'kerjasama.sk.edit',
                      params: { idSk: listData.item.idSk }
                    }}},[_c('i',{class:'ri-ball-pen-fill m-0'})]),_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Lihat Detail'),expression:"'Lihat Detail'"}],staticClass:"btn iq-bg-info rounded-sm ml-2 mb-1",attrs:{"size":"sm","role":"button","to":{
                      name: 'kerjasama.sk.detail',
                      params: { idSk: listData.item.idSk }
                    }}},[_c('i',{staticClass:"ri-search-line m-0"})]),_c('b-button',{staticClass:"btn iq-bg-secondary rounded-sm ml-2 mb-1",attrs:{"variant":"light"},on:{"click":function($event){return _vm.showDokumenKs(
                        listData.item.idSk,
                        listData.item.nomorSuratKuasa
                      )}}},[_c('i',{staticClass:"ri-file mr-1"}),_vm._v("Cetak Dokumen")])],1)]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-danger font-weight-bold mx-auto text-center"},[_vm._v(" Data tidak ditemukan ")])]},proxy:true},{key:"emptyfiltered",fn:function(){return [_c('div',{staticClass:"text-danger font-weight-bold mx-auto text-center"},[_vm._v(" Data tidak ditemukan ")])]},proxy:true}])}),_c('b-row',[_c('b-col',[_c('b-form-group',{staticClass:"d-flex",attrs:{"label":"Per page:","label-for":"per-page-select","label-size":"sm"}},[_c('b-form-select',{staticClass:"ml-2",attrs:{"id":"per-page-select","options":_vm.pageOptionsSk,"size":"sm"},model:{value:(_vm.perPageSk),callback:function ($$v) {_vm.perPageSk=$$v},expression:"perPageSk"}})],1)],1),_c('b-col',[_c('b-pagination',{staticClass:"float-right",attrs:{"total-rows":_vm.totalRowsSk,"per-page":_vm.perPageSk},model:{value:(_vm.currentPageSk),callback:function ($$v) {_vm.currentPageSk=$$v},expression:"currentPageSk"}})],1)],1)],1)]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }